<script>
import Gen from '@helper/Gen';
import BOGen from '@helper/BOGen';
import GlobalVue from '@helper/Global.vue';

export default {
    extends: GlobalVue,
    data(){
        return {
            input: {},
        }
    },
    computed:{
        yearcopy(){
            return Gen.yearCopy(this.web.yearcopy)
        }
    },
    mounted(){
        this.refreshVariable()
    },
    methods:{
        submit(e){
            if(e.btnLoading()) return;
            BOGen.apirest("/login", this.input, (err, resp)=>{
                e.btnUnloading()
                if(err) return console.log(err);
                if(resp.error){
                    return Gen.info(resp.message, 'danger')
                }
                Gen.putStorage("user", resp.user)
                Gen.putStorage("botk", resp.token)
                Gen.info(resp.message, 'success', 300).then(()=>{
                    this.$router.push({name:"BoDashboard"})
                })
            }, 'POST')
        },
    }
};
</script>

<style lang="scss" scoped>
  #wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position-y: 30%;
  }
</style>

<template>
<section id="wrapper" :style="'background-image: url('+assets('bo_images', 'bodybg.jpg')+')'">
    <div class="login-box">
        <div class="white-box">
            <div class="text-center p-20">
                <VImg style="width: 50%;" :src="baseUrl+'/frontend/images/logo.png'" alt="Farmsco"></VImg>
            </div>
            <VForm @resp="submit" class="form-horizontal" method="post">
            <h3 class="box-title m-b-20">Sign In</h3>
            <div class="info"></div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <input class="form-control" type="text" v-model="input.username" name="username" required placeholder="username">
                </div>
            </div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <input class="form-control" type="password" v-model="input.password" name="password" placeholder="password">
                </div>
            </div>
            <div class="form-group text-center">
                <div class="col-xs-12">
                    <button class="btn btn-danger btn-lg btn-block text-uppercase waves-effect waves-light btn-loading" type="submit">Log in</button>
                </div>
            </div>
            </VForm>
        </div>
        <div class="col-sm-12 text-center copyright">
            <p>
                {{yearcopy}} &copy; Farmsco. All Rights Reserved.
                <br/> Powered by <a target="_blank" href="http://www.lingkar9.com">lingkar9</a>
            </p>
        </div>
    </div>
</section>
</template>